<template>
    <div>
        <div>DOC EN COURS DE REALISATION</div>
        <div style="width : 100%">
            <vsa-list style="max-width : 100%!important">
                <vsa-item>
                    <vsa-heading>
                    Choix de la technologie ?
                    </vsa-heading>
                
                    <vsa-content>
                        Pour le développement de l'application web <b>maisonier gestion</b> partie frontend il a fallu choisir un framework de développement.
                        <p>Parmi les différentes options qui s'offraient nous avions le chois entre vueJS, angular et React JS</p>
                        <p>Dans le but de développer rapidement, notre choix s'est porté sur le framework VueJS</p>
                    </vsa-content>
                </vsa-item>
            </vsa-list> 
            <vsa-list style="max-width : 100%!important">
                <vsa-item>
                    <vsa-heading>
                    Qu'est ce que VueJS ?
                    </vsa-heading>
                
                    <vsa-content>
                        Créé en 2014 par Evan You, Vue JS est ce que l’on appelle un Framework front évolutif qui se dédie à la création d’interfaces utilisateurs.
                        Il sait donc parfaitement allier performances et simplicité et s’associe à d’autres technos pour la conception d’applications web modernes.
                        Ce framework manipule donc avec performance l’affichage dans les navigateurs.
                    </vsa-content>
                </vsa-item>
            </vsa-list> 
                     <vsa-list style="max-width : 100%!important">
                <vsa-item>
                    <vsa-heading>
                    Quels sont ses avantages ?
                    </vsa-heading>
                
                    <vsa-content>
                        Créé en 2014 par Evan You, Vue JS est ce que l’on appelle un Framework front évolutif qui se dédie à la création d’interfaces utilisateurs.
                        Il sait donc parfaitement allier performances et simplicité et s’associe à d’autres technos pour la conception d’applications web modernes.
                        Ce framework manipule donc avec performance l’affichage dans les navigateurs.
                    </vsa-content>
                </vsa-item>
            </vsa-list> 
        </div>
       
    </div>
        
</template>
<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
export default {
    name: 'doc',
    components: {
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon
    },
    computed: {
  
    },
    data: () => ({
        
    }),
    watch: {

    },

    beforeMount() {

    },
    mounted(){

    },
    methods: {


    },
}
</script>
<style scoped>

</style>
